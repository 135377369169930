import React, { useState } from "react"
import { Link } from "gatsby"

import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"

import Img from "gatsby-image"

function ServiceCard({ title, url, image, alt, children }) {
  const cardWidth = "24rem"
  const [border, setBorder] = useState("light")

  const changeBorder = () => {
    setBorder("dark")
  }

  return (
    <Col md={6} lg={4}>
      <Card border={border} style={{ width: cardWidth }}>
        <Link to={url} onClick={changeBorder}>
          {image && <Img className="card-img-top" fluid={image} alt={alt} />}
        </Link>
        <Card.Body>
          <Card.Title>
            <Link to={url} onClick={changeBorder}>
              {title}
            </Link>
          </Card.Title>
          <Card.Text>{children}</Card.Text>
          <Link className="card-link" to={url} onClick={changeBorder}>
            Learn More
          </Link>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default ServiceCard
