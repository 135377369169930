import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import SectionTitle from "../LayoutComp/SectionTitle"
import ServiceCard from "./serviceCard"

const ServicesSection = () => {
  const data = useStaticQuery(graphql`
    query {
      imagesJson(pageName: { eq: "services-section" }) {
        gallery {
          image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)
  const images = data.imagesJson

  let gallery = []

  images.gallery.forEach(result => {
    gallery = [...gallery, result.image.childImageSharp.fluid]
  })

  return (
    <>
      <section>
        <Container>
          <Row>
            <SectionTitle>Services</SectionTitle>

            <ServiceCard
              title="Home Remodeling"
              img="home-remodeling.jpg"
              image={gallery[0]}
              url="/services/home-remodeling"
              alt="Home Remodeling- Living Room"
            >
              We are full service company that will take care of every aspect of
              your project.
            </ServiceCard>

            <ServiceCard
              title="Kitchen Remodeling"
              img="kitchen-remodeling.jpg"
              image={gallery[1]}
              url="/services/kitchen-remodeling"
              alt="Remodeled kitchen - custom countertops"
            >
              We will work hand and hand with you to make your kitchen beautiful
              and functional.
            </ServiceCard>

            <ServiceCard
              title="Bathroom Remodeling"
              img="bathroom-remodeling.jpg"
              image={gallery[2]}
              url="/services/bathroom-remodeling"
              alt="Bathroom remodeling - shower and cabinets"
            >
              We're experts at transforming your old bath into the bathroom of
              your dreams.
            </ServiceCard>

            <div className="w-100 py-4 d-none d-lg-block"></div>

            <ServiceCard
              title="Home Additions"
              img="home-additions.jpg"
              image={gallery[3]}
              url="/services/home-additions"
              alt="Home additions - room addition"
            >
              Add more living space and square footage, as well as boost the
              value of your home.
            </ServiceCard>

            <ServiceCard
              title="Decks &amp; Porches"
              img="decks-and-porches.jpg"
              image={gallery[4]}
              url="/services/decks-and-porches"
              alt="Custom deck with a pool"
            >
              We specialize in making your home more beautiful, while increasing
              it's value.
            </ServiceCard>

            <ServiceCard
              title="Custom Carpentry"
              img="custom-carpentry.jpg"
              image={gallery[5]}
              url="/services/custom-carpentry"
              alt="Carpentry workshop"
            >
              We can create custom cabinets, countertops, accessories, and
              finish.
            </ServiceCard>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ServicesSection
