import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"

import MainSection from "../../components/MainSection"
import ServicesSection from "../../components/ServicesSection"

export const query = graphql`
  query {
    imagesJson(pageName: { eq: "service-page" }) {
      background {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

const ServicePage = ({ data }) => {
  const images = data.imagesJson

  return (
    <Layout>
      <SEO title="Services" />
      <MainSection image={images.background.childImageSharp.fluid}>
        <p className="lead">Bob Keach &amp; Sons Carpentry</p>
        <h1>Services</h1>
        <h2>Offering high-quality remodeling service since 1984.</h2>
      </MainSection>

      <ServicesSection />
    </Layout>
  )
}

export default ServicePage
